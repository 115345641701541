import * as React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { CapsuleButton } from '@components/atoms'
import { Header, Masthead, TransitionMask } from '@components/organisms'
import { Grid, GridItem } from '@components/grid'
import { PageProps } from '@types'
import { lazy } from '@loadable/component'


const LazyFgm = lazy(() => import('@components/threedee/Fgm'))


const IndexPage = ({data: { 
  sanityPage: {
    description,
    gridItems,
    mastheadImage,
    mobileMastheadImage,
    reflectionImage,
    modelPosition,
    modelPositionMobile,
  }
}}: PageProps) => (
  <>
    {reflectionImage && mastheadImage && (
      <Masthead {...{ reflectionImage, mastheadImage, mobileMastheadImage }} >
        <LazyFgm {...{ modelPosition, modelPositionMobile }}  />
      </Masthead>
    )}
    <Header sticky />
    <main>
      <Description>{description}</Description>
      <Center>
        <CapsuleButton to="/info">More Info</CapsuleButton>
      </Center>
      <Grid>
        {gridItems.filter(p => !!p).map((item) => (
          <StyledGridItem key={item._key} {...item} paddedBackgroundColor={item.post?.paddedBackgroundColor} >
            <Year>{item.post?.year}</Year>
          </StyledGridItem>
        ))}
      </Grid>
    </main>
    <TransitionMask />
  </>
)


const Description = styled.p`
  max-width: 90%;
  @media only screen and (min-width: 768px) {
    max-width: 800px;
  }
  @media only screen and (min-width: 1440px) {
    max-width: 920px;
  }
  margin: 1em auto 0 auto;
  text-align: center;
`

const Center = styled.div`
  text-align: center;
  margin: 1em auto;
`


const StyledGridItem = styled(props => <GridItem {...props} />)`
  img, video {
    border-radius: 0px !important;
    max-height: 800px;
  }
`

const Year = styled.div`
  text-align: right;
`


export const query = graphql`
  query HomePageQuery {
    sanityPage(slug: {current: {eq: "home"}}) {
      ...pageFields
    }
  }
`



export default IndexPage